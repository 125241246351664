.form {
    flex-direction: column;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: lightgray;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: lightgray;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: lightgray;
  }

/* custom styles */

.button, .input {
    padding: 0.85em;
    padding-left: 2em;
    padding-right: 2em;
    margin-bottom: 1em;
    border-radius: 50px;
}

.button {
  outline: none;
  font-family: 'Oleo Script', cursive;
  font-size: 1.2em;
  padding: 0;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border: none;
  background-color: #7FCAA5;
  letter-spacing: 1.1px;
}

.input {
    border: 4px solid #DBDDDF;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 1.4px;
}

.input:focus {
    outline: none;
    border-color: #7FCAA5;
}

.input:focus::placeholder {
    color: grey;
}


/*additional styles*/

.vertical-center {
  justify-content: center;
}
