h4 {
    font-family: 'Oleo Script', cursive;
    font-size: 3em;
}

.link {
    flex-basis: 10%;
    margin: 0 2em 5em 2em;
}


.link:hover {
    cursor: pointer;
}

.link-header {
    text-align: center;
}

.link-box {
    position: relative;
}

.link-description {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Open Sans', sans-serif;
    color: white;
    font-weight: bold;
    letter-spacing: 1.3px;
    font-size: 1em;
    line-height: 1.4em;
}

.link-overlap-container {
    position: relative;
    text-align: center;
}