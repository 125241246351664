
/*TESTIT*/
.testi {
    border: 1px solid red;
}
/**/

body {
    overflow-x: hidden;
}

.flex {
    display: flex;
}

.opacity {
    opacity: 0.2;

     /* IE 8 */
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";

     /* IE 5-7 */
     filter: alpha(opacity=20);
 
     /* Netscape */
     -moz-opacity: 0.2;
 
     /* Safari 1.x */
     -khtml-opacity: 0.2;
}

.highlight-yellow {color: #FAA41A;}
.highlight-purple {color: #85459A;}
.highlight-green {color: #2BB673;}

.no-underline {
    text-decoration: none;
}

.no-underline:hover {
    color: #c58215;
}

/*-------------------imager cover section-----------------*/
.front-bg-image {
    position: relative;
    height: 100vh;
    width: 100%;
    object-fit: cover;
    z-index: -999;
}

.kaari, .lock, .fp-call-for-action {
    position: absolute;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
}

.kaari {
    z-index: 1;
    width: 28em;
    bottom: -1px;
}

.lock {
    z-index: 2;
    width: 5em;
    bottom: 2.3em;
    transform: rotate(17deg);
}

.fp-call-for-action {
    z-index: 3;
    width: 5em;
    text-align: center;
    font-family: 'Oleo Script', cursive;
    color: #53B9C2;
    font-size: 1.5em;
    bottom: 7px;
}

.flex-column {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slogan {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Alex Brush', cursive;
    color: white;
    font-size: 3em;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}

.heart {
    margin: 0 0.7em;
    width: 7%;
}




/*----------------------- intro image row ---------------------------*/

.intro-image-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7em;
}

/*lora image & name container*/
.intro-image {
    position: relative;
    width: 20em;
    height: 20em;
    margin: 0 8em 0 8em;
}

.lora {
    object-fit: cover;
    border-radius: 50%;
    height: 20em;
    width: 20em;
}

.lora-heart {
    height: 40%;
    width: 40%;
}

/*container for .name and .lora-heart*/


.lora-name {
    position: absolute;
    display: flex;
    z-index: 3;
    bottom: -0.5em;
    left: 11em;
    transform: rotate(-8deg);
    /* TODO: moz-transform ym.*/
}

.name {
    display: flex;
    align-items: center;
    font-family: 'Alex Brush', cursive;
    font-size: 4em;

}

.kukka-br.reverse{
    transform: rotate(180deg);
    /* TODO: moz-transform ym.*/
}





/*-------------------------------------- 1st section ---------------------------------*/

.section-1-container {
    position: relative;
    display: flex;
    top: -2em;
}

.text-over-element {
    top: 25%;
    position: absolute;
    margin-left: 3em;
    margin-right: 5em;
}

.quote {
    width: 50%;
    text-align: right;
    font-family: 'Oleo Script', cursive;
    font-size: 2.7em;
    line-height: 1.2em;
}

.general-text {
    font-family: 'Open Sans', sans-serif;
    padding-left: 6em;
    flex: 1;
    font-size: 1.15em;
    align-self: center;
    line-height: 1.2em;
}


/*1st call for action */

.cfa-container {
    background-color: #FAA41C;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 3em;
    padding-right: 3em;
}

.cfa-container > * {
    padding: 1.5em;
    width: 32%;
}

.join-mailinglist {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    letter-spacing: 1.3px;
    font-size: 0.87em;
    line-height: 1.4em;
    color: white;
}



/*----------------------- Lora introduction ---------------------------*/

.introduction {
    margin-top: 5em;
    margin-left: 5em;
    margin-right: 5em;
}

.lora2 {
    width: 40%;
    height: 40%;
    -webkit-box-shadow: -37px -13px 81px -11px rgba(0,0,0,0.10);
    -moz-box-shadow: -37px -13px 81px -11px rgba(0,0,0,0.10);
    box-shadow: -37px -13px 81px -11px rgba(0,0,0,0.10);
    align-self: center;
}

.intro-text-container {
    flex-direction: column;
    margin-right: 3em;
}

.intro-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.15em;
    line-height: 1.3em;
    margin-right: 5em;
}

.intro-header {
    font-family: 'Oleo Script', cursive;
    font-size: 2.7em;
    line-height: 1.2em;
    height: 2.3em;
    margin-bottom: 0.5em;
}

.intro-header-hi {
    font-size: 1.4em;
    transform: rotate(-10deg);
}

.intro-header-name {
    align-self: flex-end;
}




/*----------------------- Second Section ---------------------------*/

.element-2 {
    position: relative;
    top: -4em;
}

.section-2-spacing {
    line-height:1.9em;
}

/*----------------------- Third Section ---------------------------*/

.container-3 {
    position: relative;
    top: -18em;
    margin-bottom: 10em;
}

.element-3 {
    position: absolute;
    width: 100%;
    opacity: 0.2;
    z-index: 1;
    top: 0;

     /* IE 8 */
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";

     /* IE 5-7 */
     filter: alpha(opacity=20);
 
     /* Netscape */
     -moz-opacity: 0.2;
 
     /* Safari 1.x */
     -khtml-opacity: 0.2;
}

.cont-3-text-cont {
    position: relative;
    top: 13em;
    margin-left: 3em;
    margin-right: 3em;
    z-index: 2;  
}

.lora-3 {
    object-fit: cover;
    object-position: 100% 0%;
    width: 40vw;
    height: 40vw;  
}

.cont-3-text {
    margin-left: 2em;
    position: relative;
    font-size: 1em;
}


/*----------------------- Link Section ---------------------------*/
.link-container {
    margin-left: 3em;
    margin-right: 3em;
    margin-bottom: 5em;
    flex-wrap: wrap;
    justify-content: center;
}

/*----------------------- Fourth Section ---------------------------*/

.reverse {
    flex-direction: row-reverse;
}

.element-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.alt-padding {
    padding-left: 2em;
    padding-right: 3em;
}

.quote-extra-spacing {
    margin-top: 2em;
    margin-right: 1em;
}

.section-3-container {
    top: -12em;
    margin-bottom: 0;
}


/*----------------------- Fifth Section ---------------------------*/
.container-5 {
    background: rgba(43, 182, 115, 0.2);
    text-align: center;
}

.section-5-header {
    font-family: 'Great Vibes', cursive;
    font-size: 3.2em;
    padding-top: 1.5em;
    margin-bottom: 0.8em;
    letter-spacing: 2px;
}

.section-5-text {
    font-family: 'Oleo Script', cursive;
    color:  #2BB673;
    font-size: 1.7em;
    line-height: 1.4em;
    letter-spacing: 2px;
    padding-bottom: 2em;
}



/*----------------------- CFA 2 ---------------------------*/
.cfa-2 {
    background-color: #46BF4E;
}

.button-yellow {
    background-color: #FBAF2C;
}

.cfa-logo-container {
    justify-content: center;
    align-items: center;
}

.cfa-number-two {
    justify-content: center;
    padding: 0 0 0 0;
}

.cfa-2-text {
    font-family: 'Oleo Script', cursive;
    color:  white;
    font-size: 2em;
    line-height: 1.4em;
    letter-spacing: 1px;
}

.flex-column-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


/*----------------------- Footer ---------------------------*/

footer {
    position: relative;
    margin-top: 10em;
    padding-top: 20px;
    background-color:  #2BB673;
    padding-bottom: 1em;
}

.scenery {
    object-fit: cover;
    height: 150%;
    width: 150%;
}

.logo-alt {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    transform: translateY(-7em);
}

.logo-alt-text {
    text-align: center;
    font-family: 'Oleo Script', cursive;
    color: white;
    font-size: 3.7em;
    letter-spacing: 2px;
}

.three-dancers-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    height: 20%;
    margin-bottom: 3em;
}

.company-details {
    margin: 3em 4em 10em 4em;
    font-family: 'Oleo Script', cursive;
    color: white;
    font-size: 0.9em;
    line-height: 2em;
}



/*---------- responsive -------------*/

@media screen and (max-width: 1000px) {
    .section-3-mobile {
        flex-direction: column;
    }

    .section-3-quote {
        margin-bottom: 3em;
        margin-left: 5em;
    }

    .container-5 {
        margin-top: 13em;
    }
}

@media screen and (max-width: 950px) {
    .element-one-size {
        transform: translateX(-8em);
    }

    .section-2-container-size {
        margin-bottom: 7em;
    }

    .section-3-mobile {
        flex-direction: column;
    }

    .section-3-quote {
        margin-left: 3em;
    }

    .container-5 {
        margin-top: 25em;
    }

}

@media screen and (max-width: 700px) {
    .text-over-element {
        flex-direction: column;
        margin-right: 3em;
        margin-top: 3em;
    }

    .quote {
        width: 100%;
        text-align: left;
        margin-bottom: 2em;
    }

    .section-text-size {
        margin-top: 3em;
        padding-left: 0;
        text-align: center;
    }

    .section-3-quote {
        margin-left: 0;
    }

    /*1st CFA*/
    .cfa-container {
        flex-direction: column-reverse;
        margin-top: 6em;
        padding-right: 6em;
    }

    .cfa-container > * {
        width: 100%;
    }

    .join-mailinglist {
        text-align: center;
    }

    /*intro*/
    .introduction {
        flex-direction: column;
        align-items: center;
        margin-bottom: 5em;
    }

    .intro-text-container {
        margin-right: 0;
        margin-bottom: 3em;
    }

    .intro-text {
        padding-right: 0;
        margin-right: 0;
        text-align: center;
    }

    .lora2 {
        width: 50%;
    }

    .section-2-spacing {
        padding-left: 0;
        margin-top: 5em;
    }

    .text-over-element-element-two {
        margin-top: -1em;
    }

    .container-3 {
        margin-top: 17em;
    }

    .cont-3-text-cont {
        flex-direction: column;
        margin-left: 0;
        align-items: center;
        transform: translateY(-10em);
        padding-right: 0;
    }

    .lora-3 {
        width: 50vw;
        height: 50vw;  
    }

    .cont-3-text {
        margin-top: 5em;
    }

    .link-container {
        transform: translateY(-19em);
    }

    .link-container {
        margin-left: 0em;
        margin-right: 0em;
    }

    .section-3-container {
        transform: translateY(-15em);
    }

    .quote-element-three {
        transform: translateY(-4em);
    }

    .section-four-text {
        text-align: center;
        padding-right: 0;
    }

    .container-5 {
        transform: translateY(8em);
    }

    .cfa-2 {
         flex-direction: column;
         padding-top: 3em;
         padding-right: 5em;
    }

    .flex-column-2 {
        align-items: center;
        margin-right: 0;
    }

    .cfa-2-text {
        text-align: center;
        width: 75%;
    }

}

@media screen and (max-width: 570px) {

    .section-2-container-size {
        margin-bottom: 20em;
    }
}


@media screen and (max-width: 500px) {
    .lora-name {
        left: 7em;
        bottom: -2em;
    }

    .cfa-container {
        padding-right: 3em;
        padding-left: 0.7em;
        margin-top: 13em;
    }

    .button, .input {
        padding-top: 1em;
        padding-bottom: 1em;
    }

    .introduction {
        margin-left: 3em;
        margin-right: 3em;
    }

    .lora2 {
        width: 70%;
    }

}


