
.menu-button {
    position: fixed;
    z-index: 999999;
    display: flex;
    border: 10px solid #30BCCB;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    right: 0;
    margin-top: 3em;
    margin-right: 3em;

    width: 70px;
    height: 70px;
}