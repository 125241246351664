.menu-navigation-container {
    position: fixed;
    z-index: 5;
    /*transform: rotate(-45deg);*/
    right: -270px;
    top: -400px;
    background-color: #54C2CB;
    height: 150vh;
    width: 60vw;
    border-radius: 50%;
    border: 1em solid white;
}

.menu-navigation-content {
    position: absolute;
    z-index: 6;
    transform: rotate(45deg);
    border: 1px solid red;
    left: 500px;
    top: 500px;
}

.menu-navigation-content > div {
    color: white;
}