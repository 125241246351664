/*-------- Comment container  ----------*/
.comments-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4em;
    justify-content: space-between;
    padding: 0 2em 0 2em;
}

.comment-list-item {
    width: 42%;
}


/*-------- Comment  ----------*/
.comment-container {
    flex-direction: column;
    justify-content: space-between;
    margin: 2em 2em 2em 2em;
}

.comment-text {
    padding: 1em 0 1em 0;
    margin: 0 0 0 0;
    text-align: justify;
    text-justify: inter-word;
    line-height: 1.8em;

    font-family: 'Open Sans', sans-serif;
    font-size: 1.15em;
    line-height: 1.2em;
}

.bar {
    height: 10px;
    background-color: #FAA41A;
    border-radius: 4px;
}

.commentator-row-container {
    width: 100%;
    align-items: center;
}

.commentator {
    padding: 0 0 0 0 ;
    text-align: center;
    font-family: 'Oleo Script', cursive;
    width: 30%;
    font-size: 1.2em;
}

.width-30 {
    width: 35%;
}

/* Moon logo */
.moon-container {
    position: relative;
    margin: 2em 2em 2em 2em;
    width: 41.7%;
    height: 200px;
}

.moon-logo {
    max-height: 100%;
    max-width: 100%;

    opacity: 0.2;

     /* IE 8 */
     -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";

     /* IE 5-7 */
     filter: alpha(opacity=20);
 
     /* Netscape */
     -moz-opacity: 0.2;
 
     /* Safari 1.x */
     -khtml-opacity: 0.2;
}

.moon-header {
    position: absolute;
    top: 30%;
    left: 25%;
    font-family: 'Oleo Script', cursive;
    font-size: 4em;
}


@media screen and (max-width: 870px) {
    .comment-list-item {
        width: 100%;
    }

    .moon-container {
        margin-left: 6em;
    }
}
