.badge-container {
    position: relative;
    margin: none;
    top: -6em;
    transform: rotate(8deg);
}

.badge-background {
    position: absolute;
    background-color: #D00886;
    height: 15em;
    width: 15em;
    border-radius: 50%;
    border: 8px solid white;
    left: 50%;
    margin-left: -5em;
}

.badge-text {
    text-shadow: -5px -4px 0 #D00886,
                 2px 3px 0 #D00886;
    position: absolute;
    z-index: 3;
    font-family: 'Oleo Script', cursive;
    font-size: 4em;
    text-align: center;
    width: 300px;
    left: 50%;
    margin-left: -100px;
    top: 1em;
    letter-spacing: 1.2px;
    line-height: 1.3em;   
}

@media screen and (max-width: 900px) {
    .badge-background {
        height: 10em;
        width: 10em;
    }

    .badge-text {
        font-size: 3em;
        left: 42%;
    }

    .badge-container {
        margin-bottom: 4em;
    }
}